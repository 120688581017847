import { Component, ComponentProps } from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";
import c from "class-c";
import { Event, eventHandler } from "solid-u";

import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import Identity from "../flow/Identity";

import styles from "./Link.module.scss";

declare namespace Link {
  type Props = ComponentProps<typeof A> & {
    // Not required by default
    href: string;
    variant?: "container" | "unstyled";
    // typeof A["state"] is unknown, causes type error
    state?: undefined;
  };
}

function Link(_props: Link.Props) {
  const {
    children,
    variant = "unstyled" as never,
    as = A,
    class: className,
    onClick,
    ...props
  } = Link.PropsProvider.useMerge(_props) satisfies D;

  return (
    <Dynamic
      component={as}
      class={c`
        ${styles[`variant:${variant}`]}
        ${className}
      `}
      onClick={(e: Event.FromComponent<typeof A, "onClick">) => {
        // TODO: All navigations must be tracked with AppRouter so we can compute history
        // AppRouter[props.replace ? "replace" : "push"](props.href);

        eventHandler(onClick)(e);
      }}
      {...props}
    >
      {children}
    </Dynamic>
  );
}

declare namespace Link.If {
  type Props = Link.Props & {
    when: unknown;
  };
}

Link.If = ({ when, children, ...props }: D<Link.If.Props>) => {
  return (
    <Dynamic component={when ? Link : Identity} {...props}>
      {children}
    </Dynamic>
  );
};

Link.Doc = (props: D<Link.Props>) => {
  return (
    <Link target="_blank" {...props} class={c`underline ${props.class}`} />
  );
};

Link.PropsProvider = createPropsProvider<
  Link.Props & {
    as?: Component<Partial<ComponentProps<"a">> & { href: string }> | "a";
    classBy?(props: Link.Props): string;
  }
>("Link", {
  class: (context, props) =>
    c`${context.class} ${props.class} ${context.classBy?.(props)}`,
});

export default Link;
